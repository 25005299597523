@import './colors.scss';
@import url('react-confirm-alert/src/react-confirm-alert.css');

body.react-confirm-alert-body-element {
    overflow: hidden;
}
  
.react-confirm-alert-blur {
    filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

$blur-time: 0.2s;
$delay-time: 0.1s;

.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(1px);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn $blur-time $delay-time forwards;
    -moz-animation: react-confirm-alert-fadeIn $blur-time $delay-time forwards;
    -o-animation: react-confirm-alert-fadeIn $blur-time $delay-time forwards;
    animation: react-confirm-alert-fadeIn $blur-time $delay-time forwards;
}
  
.react-confirm-alert-body {
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 8px 4px;;
    color: $primary;
}
  
.react-confirm-alert-svg {
    position: absolute;
    top: 0;
    left: 0;
}
  
.react-confirm-alert-body > h1 {
    margin-top: 0;
}
  
.react-confirm-alert-body > h3 {
    margin: 0;
    font-size: 16px;
}
  
.react-confirm-alert-button-group {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
  
.react-confirm-alert-button-group > button {
    border-radius: 20px;
    width: 120px;
    height: 25px;
    padding: 0 10px;
    font-size: 10pt;
    font-weight: bold;
    &:hover {
        color: white;
        background-color: $secondaryBlue;
    }
}

.react-confirm-yes-button {
    color: white !important;
    background-color: $secondaryBlue !important;
    border: 0px solid !important;
    margin-right: 0px !important;
    &:hover {
        background-color: $secondaryBlue2 !important;
    }
}

.react-confirm-no-button {
    color: $secondaryBlue !important;
    background-color: white !important;
    border: 2px solid $secondaryBlue !important;
    &:hover {
        color: white !important;
        background-color: $secondaryBlue !important;
    }
}

@-webkit-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
  
@-moz-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
  
@-o-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
  
@keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}