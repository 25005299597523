@import './colors.scss';

div.scrollableList ::-webkit-scrollbar {
  width: 8px;
}

div.scrollableList ::-webkit-scrollbar-track {
  background-color: var(--secondaryGray3);
  border-radius: 10px;
}

div.scrollableList ::-webkit-scrollbar-thumb {
  background-color: var(--secondaryGray);
  border-radius: 10px;
}

div.scrollableList2 ::-webkit-scrollbar {
  width: 8px;
}

div.scrollableList2 ::-webkit-scrollbar-track {
  // background-color: var(--secondaryGray4);
  background-color: var(--primary3);
  border-radius: 10px;
}

div.scrollableList2 ::-webkit-scrollbar-thumb {
  background-color: var(--primary2);
  border-radius: 10px;
}