
:root {
  --primary: #001a70;
  --primary2: #808cb7;
  --primary3: #bfc6db;
  --primary4: #dfe2ed;
  --secondaryBlue: #2bb4d3;
  --secondaryBlue2: #95dae9;
  --secondaryBlue3: #caecf4;
  --secondaryBlue4: #e5f6f9;
  --secondaryYellow: #ffd000;
  --secondaryYellow2: #ffe880;
  --secondaryYellow3: #fff3bf;
  --secondaryYellow4: #fff9df;
  --secondaryGray: #c6d2ef;
  --secondaryGray2: #e3e8f7;
  --secondaryGray3: #f1f4fb;
  --secondaryGray4: #f8f9fd;
  --secondaryGreen: #15834A;
  --secondaryGreen2: #51AC72;
  --secondaryGreen3: #8DD59A;
  --secondaryGreen4: #C8FFC2;
}

.App {
  height: 100vh;
}

/* FONTS */
@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/OpenSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/OpenSans-Light.ttf') format('truetype');
  font-weight: light;
  font-style: normal;
}

h1, h2, label {
  font-family: 'OpenSans', sans-serif !important;
  font-weight: bold;
  font-style: normal;
  color: var(--primary);  /* Do we want to make all the text primary or just black? */
}

span, input, div, button, li {
  font-family: 'OpenSans', sans-serif !important;
  font-weight: normal;
  font-style: normal;
  color: var(--primary);  /* Do we want to make all the text primary or just black? */
}