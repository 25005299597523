@import './colors.scss';

.slider-mark {
    width: 1px;
    height: 64px;
    border: none;
    border-radius: 0;
    margin-left: -0.5px; /* This centers the line around the tick mark */
    margin-top: -5px;
    background-image: repeating-linear-gradient(0deg, var(--primary3) 0 1px, transparent 1px 3px, var(--primary3) 3px 4px);
    z-index: 2;
}
  
.slider-mark:nth-of-type(1) {
    width: 2px;
    background-image: repeating-linear-gradient(0deg, var(--primary) 0 1px);
    z-index: 4;
}
  
.slider-mark:nth-of-type(even) {
    width: 2px;
    background-image: none;
    z-index: 4;
}