@import './colors.scss';

#table {
    border-collapse: collapse;
    table-layout: fixed;
    width: fit-content;
    height: fit-content;
    margin-top: 20px;
  }
  
  #table thead {
    background-color: #eee;
  }
  
  #table th, tr, td {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }
  
  #table th, td {
    padding-left: 10px;
    text-align: start;
    border-style: hidden;
  }
  
  #table th {
    background-color: $primary;
    color: white;
  }

  #table tr {
    line-height: 30px;
  }

  #table tr:nth-child(even) {
    background-color: $secondaryGray2;
  }