.loading-icon {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 40px;
}

.loading-icon div {
    position: absolute;
    top: 15px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loading-icon div:nth-child(1) {
    left: 0px;
    animation: loading-icon1 0.6s infinite;
}

.loading-icon div:nth-child(2) {
    left: 0px;
    animation: loading-icon2 0.6s infinite;
}

.loading-icon div:nth-child(3) {
    left: 20px;
    animation: loading-icon2 0.6s infinite;
}

.loading-icon div:nth-child(4) {
    left: 40px;
    animation: loading-icon3 0.6s infinite;
}

@keyframes loading-icon1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
  
@keyframes loading-icon3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
  }
  
@keyframes loading-icon2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(20px, 0);
    }
}