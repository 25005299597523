@import 'react-big-calendar/lib/sass/styles';
    
  .rbc-calendar {
    height: 100%;
    width: 100%;
  }

  .rbc-month-row {
    border: none !important;
  }

  .rbc-header {
    text-align: left;
    text-transform: uppercase;
    margin-left: 5px;
    border: none !important;
  }

  .rbc-month-view {
    border: none;
  }

  .rbc-row-content {
    height: 0px;
  }